import React, { FC, useState } from "react"
import styled from "@emotion/styled"

import {
  breakpoints,
  column,
  GridRow,
  Body as DKBody,
  BodyBold,
  ExpandableCard,
  TextLink,
  colours,
  horizontal,
  typographyStyles,
  vertical,
  Headline2,
} from "design-kit"

import {
  Table,
  TableCaption,
  TableHeadEmpty,
  TableHead,
  TableData,
} from "../../shared-components/Shared/Table"

const Title = styled(Headline2)`
  color: ${colours.offBlack};
  margin: 0 0 ${vertical.s};
`

const FAQSubheading = styled.h4`
  ${typographyStyles.bodyBold}
  margin: 0 0 ${vertical.xs};
`

const Body = styled(DKBody)`
  margin-bottom: ${vertical.xs};
`

const UL = styled.ul`
  list-style: disc;
  margin-left: ${horizontal.m};
  margin-bottom: ${vertical.s};
  ${typographyStyles.body};
`

const Section = styled.section`
  padding: ${vertical.l} 0;

  ${breakpoints.desktop`
    padding: ${vertical.xxl} 0;
  `}
`

const SectionInner = styled.div`
  ${column({
    widthDesktop: 10,
    offsetDesktop: 1,
    widthTablet: 6,
    widthMobile: 4,
  })}
`

const id1 = "what-is-a-mortgage-in-principle"
const Answer1: React.FC = () => (
  <React.Fragment>
    <Body>
      An MIP is a certificate showing how much you can borrow on your mortgage.
    </Body>
    <Body>
      You can take it to property viewings, and use it to show sellers that:
    </Body>
    <UL>
      <li>you’ve done your homework</li>
      <li>you’re serious about buying</li>
      <li>and you can afford to buy</li>
    </UL>
    <Body>
      It’s also a good way to reassure yourself, too. Your MIP will give you a
      sense of whether you can afford to borrow the amount you were hoping for.
      It’s a rough estimate, and not necessarily a guarantee, but it’s a good
      first step.
    </Body>
    <Body>
      An MIP is different from an agreement in principle (AIP) - you can read
      more about that below.
    </Body>
  </React.Fragment>
)

const id2 =
  "what-is-the-difference-between-a-mortgage-in-principle-and-an-agreement-in-principle"
const Answer2: React.FC = () => (
  <React.Fragment>
    <Body>
      Lenders and brokers sometimes say “mortgage in principle” and “agreement
      in principle” like they’re the same thing. Spoiler alert: they’re not.
    </Body>
    <Body>
      Both an MIP and AIP are certificates showing what you can borrow. But they
      happen at different stages and help you in different ways.
    </Body>
    <FAQSubheading>Mortgage in principle (MIP)</FAQSubheading>
    <Body>
      You usually get an MIP before you’ve found a property, to help you in your
      search. You can get an MIP from a broker, lender, or even an estate
      agent’s own broker.
    </Body>
    <Body>
      An MIP is the most basic check of what you can realistically borrow. To
      get one, you need just a few details about your income and deposit.
      There’s no credit check, and you don’t need to submit any documents to
      anyone.
    </Body>
    <Body>
      An MIP shows sellers that you’re likely to be approved for a mortgage, so
      it could help you stand out from other buyers when you’re making an offer.
    </Body>
    <FAQSubheading>Agreement in principle (AIP)</FAQSubheading>
    <Body>
      Usually, you only get an AIP once you’ve found a property you want to buy.
      You’ll get your AIP from a lender, though a broker can get this on your
      behalf.
    </Body>
    <Body>
      An AIP is a statement from a lender saying they’re happy to lend you a
      specified amount of money to buy a specific property. You can think of it
      like the first part of your proper mortgage application.
    </Body>
    <Body>
      To get an AIP, you need to answer more questions, send your lender
      documents, and do a credit check. But the result is a more accurate
      estimate of what you can borrow, and more certainty that you’ll be
      approved for a mortgage.
    </Body>
    <Table>
      <TableCaption>MIP and AIP compared</TableCaption>
      <tbody>
        <tr>
          <TableHeadEmpty scope="col"></TableHeadEmpty>
          <TableHead scope="col">Mortgage in principle</TableHead>
          <TableHead scope="col">Agreement in principle</TableHead>
        </tr>
        <tr>
          <TableHead scope="row">When you get it</TableHead>
          <TableData>Before you find a property</TableData>
          <TableData>Once you’ve found a property</TableData>
        </tr>
        <tr>
          <TableHead scope="row">Where you get it from</TableHead>
          <TableData>Lender, broker, online calculator</TableData>
          <TableData>Lender (though a broker can get one for you)</TableData>
        </tr>
        <tr>
          <TableHead scope="row">Info you need to give</TableHead>
          <TableData>A few details about your income and deposit</TableData>
          <TableData>
            More details needed about your finances, job, spending
          </TableData>
        </tr>
        <tr>
          <TableHead scope="row">Credit check needed?</TableHead>
          <TableData>No</TableData>
          <TableData>Yes – a soft credit check</TableData>
        </tr>
        <tr>
          <TableHead scope="row">Documents needed?</TableHead>
          <TableData>No</TableData>
          <TableData>Yes</TableData>
        </tr>
        <tr>
          <TableHead scope="row">
            Accurate estimate of what you can borrow?
          </TableHead>
          <TableData>Basic check</TableData>
          <TableData>Accurate estimate</TableData>
        </tr>
        <tr>
          <TableHead scope="row">What’s the step after this?</TableHead>
          <TableData>An AIP or full mortgage application</TableData>
          <TableData>Full mortgage application</TableData>
        </tr>
      </tbody>
    </Table>
    <FAQSubheading>They’re not a promise</FAQSubheading>
    <Body>
      Neither an MIP or AIP are a contract. Neither one guarantees you’ll get a
      mortgage. You’ll still need to go through the full mortgage application
      process – a far more detailed check of your finances – and you might still
      be rejected. Not the most cheery thing, we know, but it’s good to be
      prepared.
    </Body>
    <FAQSubheading>They’re not compulsory</FAQSubheading>
    <Body>
      You don’t have to get an MIP or AIP. If you find a property you like, you
      could in theory go straight to a full mortgage application.
    </Body>
    <Body>
      The only thing is, you’d need to be extremely confident about what you can
      afford, because your mortgage application will include a hard credit
      check.
    </Body>
    <Body>
      What an MIP or AIP do is give you a little more certainty before you dive
      into a full mortgage application. Any glaring problems with your finances,
      they’ll show up early.
    </Body>
  </React.Fragment>
)

const id3 = "do-i-need-a-credit-check-to-get-an-MIP"
const Answer3: React.FC = () => (
  <React.Fragment>
    <Body>
      Nope. There’s no credit check needed for a mortgage in principle.
    </Body>
    <Body>
      Later on, when it’s time to actually apply for a mortgage, you might have
      to do a credit check. But you’ll need to give your express consent before
      that can happen, so it will never be a surprise.
    </Body>
    <BodyBold>Credit checks: the difference between an MIP and an AIP</BodyBold>
    <FAQSubheading>Mortgage in principle – no credit check</FAQSubheading>
    <Body>
      You’ll have to give a few details about your income, savings, and deposit
      amount. Then your lender or broker will automatically calculate an
      estimate of the mortgage you could get. They might ask you about your
      credit commitments, but they don’t investigate your personal credit
      history at MIP stage.
    </Body>
    <FAQSubheading>Agreement in principle – credit check</FAQSubheading>
    <Body>
      When you apply to a lender for an agreement in principle, they’ll check
      your credit score to see how you’ve managed debt before – and decide how
      risky it would be for them to lend you money.
    </Body>
    <Body>
      If they see you’ve been managing your money well, they’ll be more likely
      to offer you a mortgage in principle. But if they see lots of missed bills
      and unpaid debts in your record, that might put them off giving you a
      mortgage loan.
    </Body>
    <FAQSubheading>Credit scores: a quick refresher</FAQSubheading>
    <Body>
      A credit report is a record of how you’ve managed your money. It shows
      things like your debts, whether you’ve paid bills on time, your store
      cards, where you’ve applied for loans, when you paid those loans back… you
      get the picture.
    </Body>
    <Body>
      It comes with a credit score: that’s all the information in your report,
      summarised in one number.
    </Body>
    <Body>
      Before you apply for an agreement in principle, check your credit report
      yourself first. You can do this with{" "}
      <TextLink
        text="Experian"
        href="https://www.experian.co.uk/"
        target="_blank"
      />
      ,{" "}
      <TextLink
        text="Equifax"
        href="https://www.equifax.co.uk/"
        target="_blank"
      />{" "}
      and{" "}
      <TextLink
        text="TransUnion"
        href="https://www.transunion.co.uk/"
        target="_blank"
      />{" "}
      (previously CallCredit) – the agencies who work out your credit score in
      the UK. They each calculate it a little differently, so it’s worth getting
      a report from all three.
    </Body>
    <Body>
      Once you get it, work through this{" "}
      <TextLink
        text="credit score checklist"
        href="/mortgages-explained/what-credit-score-do-i-need"
      />{" "}
      to make sure your score is as good as it can be before you apply to
      lenders.
    </Body>
    <FAQSubheading>Do credit searches affect my credit rating?</FAQSubheading>
    <Body>There are two types of credit searches:</Body>
    <UL>
      <li>
        <b>Soft search</b>: a cursory check that won’t affect your credit score
      </li>
      <li>
        <b>Hard search</b>: a deeper dive into your credit history, that shows
        up on your report and might lower your score
      </li>
    </UL>
    <Body>
      For agreements in principle, it’s worth checking if your lender will use a
      hard or soft search in advance. If they use a hard search, it’ll show up
      on your record as a full mortgage application. One or two of these won’t
      affect your score too badly, but several over a short amount of time can
      really drag it down – as it’ll look like you’ve been rejected many times
      in a row. Not great.
    </Body>
    <Body>
      When it comes to a full mortgage application, lenders need to dig deeper
      into your finances – that’s almost always a hard search, but one you can’t
      really avoid.
    </Body>
    <Body>
      You can check your own score as often as you like. It won’t affect your
      credit rating.
    </Body>
  </React.Fragment>
)

const id4 = "how-reliable-is-an-mip-does-it-guarantee-i-will-get-a-mortgage"
const Answer4: React.FC = () => (
  <React.Fragment>
    <Body>
      If only. The truth is an MIP is no guarantee you’ll get a mortgage.
    </Body>
    <Body>
      An MIP is a basic check of what you can afford to borrow, based on a few
      questions about your income and deposit. There’s no credit check.
    </Body>
    <Body>
      Later on in your mortgage journey, you’ll have to answer more detailed
      questions about your finances, and do a credit check to get an exact
      figure. That might change the amount you can borrow.
    </Body>
  </React.Fragment>
)

const id5 = "how-do-i-get-a-mortgage-in-principle"
const Answer5: React.FC = () => (
  <React.Fragment>
    <Body>
      If you just want a rough estimate of how much you can borrow, you can get
      that quickly and easily from either a lender or broker. You can get an MIP
      from Habito in 5 minutes for free, from this very page.
    </Body>
    <Body>You won’t need any documents. You’ll just need to know:</Body>
    <UL>
      <li>your income </li>
      <li>how much you have in savings </li>
      <li>how much you’d like to put down for a deposit </li>
      <li>the approximate cost of the property you might buy </li>
      <li>some broad estimates about your monthly spending</li>
    </UL>
    <FAQSubheading>How to get an agreement in principle</FAQSubheading>
    <Body>
      An agreement in principle (AIP) is the next step, after you’ve got an MIP.
    </Body>
    <Body>
      Once you’ve found a property you like, you can get an agreement in
      principle for a mortgage on that specific property.
    </Body>
    <Body>
      You’ll need some documents, like proof of ID, proof of address and bank
      statements. If you have those to hand, you (or your broker) can apply
      online in 20 minutes. You’ll usually get a decision that same day – it can
      actually take as little as 15 minutes – with a certificate or written
      confirmation as proof.
    </Body>
  </React.Fragment>
)

const id6 = "do-I-have-to-get-an-mip"
const Answer6: React.FC = () => (
  <React.Fragment>
    <Body>
      Nope. If you wanted, you could skip it and go straight to your mortgage
      application.
    </Body>
    <Body>
      An MIP is useful if you want more certainty about what you can borrow –
      for very little effort – before committing to a full mortgage application,
      credit check and all.
    </Body>
    <Body>It’s useful, but not compulsory.</Body>
  </React.Fragment>
)

const id7 = "how-long-does-it-last-for"
const Answer7: React.FC = () => (
  <React.Fragment>
    <FAQSubheading>Mortgage in principle: no time limit</FAQSubheading>
    <Body>
      Forever, basically. There’s no time stamp on an MIP, though it’s based on
      the income and deposit you entered when you got it. So if those things
      change then your MIP won’t be as helpful any more.
    </Body>
    <FAQSubheading>Agreement in principle: 30–90 days</FAQSubheading>
    <Body>
      Your agreement in principle will last around 30–90 days, depending on the
      lender. If your circumstances or credit history change in that time (for
      example, you miss a credit card payment) that will change the validity of
      your AIP.
    </Body>
    <Body>
      If your AIP runs out before you need it, don’t worry. You can always
      re-apply.
    </Body>
  </React.Fragment>
)

const id8 = "what-happens-after-i-get-my-mip"
const Answer8: React.FC = () => (
  <React.Fragment>
    <Body>
      Your next step will be finding a home. That means heading out into the big
      bad world of property searches and viewings.
    </Body>
    <Body>
      Once you’ve found a place you like, it’s time to make an offer. Once the
      seller accepts your offer, it’s time to get a solicitor and sort your
      mortgage. As always,{" "}
      <TextLink text="we’ll be here to help" href="/home-buying" />.
    </Body>
  </React.Fragment>
)

const id9 = "does-a-mortgage-in-principle-affect-your-credit-score"
const Answer9: React.FC = () => (
  <React.Fragment>
    <Body>
      No, a mortgage in principle <strong>won’t</strong> affect your credit
      score. That’s because it uses a basic affordability calculation without
      doing a credit check.
    </Body>

    <Body>
      If you’re getting an agreement in principle from a lender, on the other
      hand, they will do a credit check, but it’s almost always going to be a
      soft credit check, which won’t affect your credit score.
    </Body>
  </React.Fragment>
)

const id10 = "how-reliable-is-a-mortgage-in-principle"
const Answer10: React.FC = () => (
  <React.Fragment>
    <Body>
      A mortgage in principle is an indication of what you’d be able to borrow
      based on your income, outgoings, and a few other bits of information.
    </Body>

    <Body>
      Because it’s a basic calculation and a cursory check against common
      eligibility criteria, it’s not a guarantee that you will be approved for a
      mortgage for that amount.
    </Body>

    <Body>
      An{" "}
      <TextLink
        href="#what-is-the-difference-between-a-mortgage-in-principle-and-an-agreement-in-principle"
        text="agreement in principle (AIP)"
      />{" "}
      uses a little more information and a credit check, so it’s a slightly more
      reliable indication that you’d be accepted for a mortgage with the lender
      you’re getting the AIP for that amount, but it’s still not a guarantee!
    </Body>

    <Body>
      Once you’re ready to apply, a mortgage expert will go through your
      situation in detail to make sure that you’re eligible and likely to be
      accepted for the mortgage you end up going for.
    </Body>
  </React.Fragment>
)

const id11 = "why-would-a-mortgage-in-principle-be-declined"
const Answer11: React.FC = () => (
  <React.Fragment>
    <Body>
      There could be a few reasons why a lender, broker, or online site might
      not give you a mortgage in principle – for example, if they think you’d
      fail one or more basic eligibility criteria, or your income or deposit is
      below a minimum threshold.
    </Body>

    <Body>
      If you’ve been prevented from getting an MIP, it’s best to{" "}
      <TextLink href="/how-can-we-help" text="speak to an expert" /> to get
      advice about your specific situation.
    </Body>
  </React.Fragment>
)

const FAQ: FC = () => {
  const [expandedId, setExpandedId] = useState<string | null>(null)

  const items = [
    {
      title: "What is a mortgage in principle (MIP)?",
      content: <Answer1 />,
      id: id1,
      isExpanded: expandedId === id1,
    },
    {
      title:
        "What’s the difference between a mortgage in principle (MIP) and an agreement in principle (AIP)?",
      content: <Answer2 />,
      id: id2,
      isExpanded: expandedId === id2,
    },
    {
      title: "Do I need a credit check to get an MIP?",
      content: <Answer3 />,
      id: id3,
      isExpanded: expandedId === id3,
    },
    {
      title: "How reliable is an MIP? Does it guarantee I’ll get a mortgage?",
      content: <Answer4 />,
      id: id4,
      isExpanded: expandedId === id4,
    },
    {
      title: "How do I get a mortgage in principle?",
      content: <Answer5 />,
      id: id5,
      isExpanded: expandedId === id5,
    },
    {
      title: "Do I have to get an MIP?",
      content: <Answer6 />,
      id: id6,
      isExpanded: expandedId === id6,
    },
    {
      title: "How long does it last for?",
      content: <Answer7 />,
      id: id7,
      isExpanded: expandedId === id7,
    },
    {
      title: "What happens after I get my MIP?",
      content: <Answer8 />,
      id: id8,
      isExpanded: expandedId === id8,
    },
    {
      title: "Does a mortgage in principle affect your credit score?",
      content: <Answer9 />,
      id: id9,
      isExpanded: expandedId === id9,
    },
    {
      title: "How reliable is a mortgage in principle?",
      content: <Answer10 />,
      id: id10,
      isExpanded: expandedId === id10,
    },
    {
      title: "Why would a mortgage in principle be declined?",
      content: <Answer11 />,
      id: id11,
      isExpanded: expandedId === id11,
    },
  ]

  return (
    <Section>
      <GridRow>
        <SectionInner>
          <Title>FAQs</Title>
          <ExpandableCard
            items={items}
            onChange={id => {
              if (expandedId === id) {
                setExpandedId(null)
              } else {
                setExpandedId(id)
              }
            }}
          />
        </SectionInner>
      </GridRow>
    </Section>
  )
}

export default FAQ
